import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

// import { StaticImage } from "gatsby-plugin-image"
// import PostCard from "../components/postCard"
import PrettyAboutCard from "../components/aboutCard"

const AboutPage = () => (
  <Layout>
    <Seo title="ÜberMich" />
    <PrettyAboutCard />
  </Layout>
)

export default AboutPage
