import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundSlider from "gatsby-image-background-slider"
import "../styles/global.css"

const AboutCard = () => (
  <BackgroundSlider
    query={useStaticQuery(graphql`
      query {
        backgrounds: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          nodes {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1080, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `)}
    initDelay={2}
    transition={4}
    duration={8}
    images={[
      "vale/projekt-einbaum.jpg",
      "vale/vale2.jpg",
      "vale/vale3.jpg",
      "vale/sense-of-self.jpg",
    ]}
    style={{
      transform: "rotate(0deg) scale(.9)",
    }}
  ></BackgroundSlider>
)

const PrettyAboutCard = () => (
  <div className="w-3/4 rounded shadow p-2 mx-auto my-4">
    <div className="w-2/5 h-64 float-left mt-2">
      <div className="w-11/12 h-full p-4 mx-auto relative">
        <AboutCard />
      </div>
    </div>
    <div className="p-4 font-courier">
      <h1 className="text-lg font-bold p-2">Über mich</h1>
      <p className="text-justify text-gray-700 py-1">
        Ich wurde 1992 in Schleching geboren. Meine Eltern sind beide Musiker
        und Musiklehrer. Von früher Kindheit an verbrachte ich die meisten Jahre
        meines bisherigen Lebens in Grassau im schönen Chiemgau.
      </p>
      <p className="text-justify text-gray-700  py-1">
        Nach dem Abitur 2011 studierte ich in Rostock zwei Semester
        Elektrotechnik, was mir fachlich extrem wenig Freude bereitete. Ich
        entschloss mich, mir bei der zweiten Berufswahl mehr Zeit zu lassen und
        mehr auf mein Gefühl zu achten. So nahm ich mir ein Jahr Zeit zur
        Neuorientierung, arbeitete als Dachdeckerhelfer und probierte viele
        Handwerksberufe aus, die mir generell mehr Spaß machten und ungewohnt
        leicht von der Hand gingen. Mit dem Beginn der Ausbildung zum
        Holzbildhauer an der Berufsfachschule für Holzbildhauerei und
        Schreinerei Berchtesgaden im Jahr 2013 konnte ich es erstmals wagen, von
        so etwas wie Berufung zu sprechen. Im Juli 2016 schloss ich diese
        Ausbildung mit Gesellenbrief und der Auszeichnung Bayerischer
        Staatspreis ab. Nach zwei Jahren freischaffender Tätigkeit begann ich
        ein Studium der bildenden Kunst mit Fachrichtung Bildhauerei – Figur an
        der Burg Giebichenstein Kunsthochschule Halle. Seit 2020 lebe und
        arbeite ich wieder im Chiemgau. Neben der Bildhauerei lebe ich für die
        Musik und baue seit 2020 exklusive E-Gitarren.
      </p>
      <p className="text-justify text-gray-700 py-1">
        Meinem unermüdlichen Schaffensdrang habe ich meistens mehr Arbeit zu
        verdanken, als ich zeitlich bewältigen kann und finde das auch gut so.
      </p>
    </div>
  </div>
)

export default PrettyAboutCard
